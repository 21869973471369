<template>
  <div class="questionnaire">
    <div class="questionnaire-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/recommend.png`)" fit="contain"></el-image>
        <div class="head-title">AI生涯规划推荐</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="questionnaire-main">
      <div class="main-option">
        <div class="option-list"
          v-for="(chat, index) in student_questionnaire_classify_list"
          :key="chat.student_questionnaire_classify_id"
          @click="selectChat(index)"
          :class="{ active: selectedQuestionnaireClassifyIndex === index }"
        >
          {{ chat.classify_name }}
        </div>
      </div>
      <div class="main-head">
        <el-steps :active="active" align-center>
          <el-step v-for="item in stepArr" :key="item.id" :title="item.title" :description="item.index + '/' + item.description"></el-step>
        </el-steps>
      </div>
      <div class="main-content">
        <div class="question-block" v-if="selectedChat">
          <h2 class="title" style="white-space: pre-wrap;">{{ selectedChat.title }}</h2>
          <div v-if="selectedChat.is_type === 1">
            <el-radio-group v-model="selectedChat.selected" class="block-arr">
              <el-radio class="option" v-for="option in selectedChat.optionArr" :key="option.label" :label="option.label">{{ option.text }}</el-radio>
            </el-radio-group>
          </div>
          <div v-if="selectedChat.is_type === 2">
            <el-input placeholder="请输入内容" v-model="selectedChat.selected" maxlength="30" show-word-limit clearable></el-input>
          </div>
          <div v-if="selectedChat.is_type === 3">
            <el-checkbox-group v-model="selectedChat.selected" class="block-arr">
              <el-checkbox class="option" v-for="option in selectedChat.optionArr" :key="option.label" :label="option.label">{{ option.text }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="question-button">
          <el-button v-if="selectedChatIndex !== 0" class="next-button" @click="clickSelected(-1)">上一题</el-button>
          <el-button v-if="selectedChatIndex !== questions.length - 1" class="next-button" @click="clickSelected(1)">下一题</el-button>
          <el-button v-if="selectedChatIndex === questions.length - 1" class="next-button" @click="submitQuestionnaire">提交问卷</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { editQuestions, getQuestionnaireList, getQuestionnaire } from "../../../request/api";

export default {
  data () {
    return {
      questions: [],
      student_questionnaire_classify_id: undefined,
      student_questionnaire_classify_list: [],
      active: 1,
      stepArr: [],
      selectedChatIndex: 0,
      selectedQuestionnaireClassifyIndex: 0
    }
  },
  computed: {
    selectedChat () {
      return this.questions[this.selectedChatIndex]
    }
  },
  methods: {
    selectChat (index) {
      this.selectedQuestionnaireClassifyIndex = index
      this.recoveryData()
      this.getQuestionnaireIndex()
    },
    recoveryData () {
      this.stepArr = [
        { id: 1, title: '单选题', description: 0, index: 1 },
        { id: 2, title: '填空题', description: 0, index: 0 },
        { id: 3, title: '多选题', description: 0, index: 0 },
      ]
      this.active = 1
      this.selectedChatIndex = 0
    },
    sendExit () {
      this.$emit('update-value')
    },
    clickSelected (number) {
      if (number === 1 && this.selectedChat.selected === null) {
        return this.$message.warning('题目未完成！')
      }

      this.selectedChatIndex += number
      if (number < 0) {
        this.stepArr[this.active - 1].index += number
      }

      if (this.selectedChat.is_type === 1) {
        this.active = 1
      } else if (this.selectedChat.is_type === 2) {
        this.active = 2
      } else if (this.selectedChat.is_type === 3) {
        this.active = 3
      }

      if (number > 0) {
        this.stepArr[this.active - 1].index += number
      }
    },
    async submitQuestionnaire () {
      if (this.questions.some(question => question.selected === null || (Array.isArray(question.selected) && question.selected.length === 0))) {
        this.$message.error('请完成问卷！')
        return
      }

      let postData = {
        questions: JSON.stringify(this.questions),
        student_questionnaire_classify_id: this.student_questionnaire_classify_id.split(',')[this.selectedQuestionnaireClassifyIndex]
      }
      const res = await editQuestions(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      if (this.student_questionnaire_classify_id === '99,100') {
        this.$router.push(
          {
            path: '/questionResult',
            query: {
              questionnaire_result_id: res.data.data
            }
          }
        )
      } else if (this.student_questionnaire_classify_id === '3') {
        this.$router.push(
          {
            path: '/questionResultOverall',
            query: {
              questionnaire_result_id: res.data.data
            }
          }
        )
      } else if (this.student_questionnaire_classify_id === '5') {
        this.$router.push(
          {
            path: '/questionResultOverall',
            query: {
              questionnaire_result_id: res.data.data
            }
          }
        )
      }
      this.$emit('getAiUser')
    },
    async getQuestionnaireIndex() {
      const res = await getQuestionnaire(this.student_questionnaire_classify_id.split(',')[this.selectedQuestionnaireClassifyIndex])

      this.questions = res.data.data.map(question => {
        if (question.is_type === 1) {
          this.stepArr[0].description += 1
        } else if (question.is_type === 2) {
          this.stepArr[1].description += 1
        } else if (question.is_type === 3) {
          this.stepArr[2].description += 1
        }

        return {
          student_questionnaire_questions_id: question.student_questionnaire_questions_id,
          title: question.title,
          is_type: question.is_type,
          optionArr: question.is_type === 2 ? question.optionArr : Object.entries(question.optionArr).map(([label, text]) => ({ label, text })),
          selected: question.is_type === 3 ? [] : null
        }
      })
    },
    // 获取数据
    async init() {
      if (this.student_questionnaire_classify_id === undefined) return

      if (this.student_questionnaire_classify_id === '99,100') {
        this.stepArr = [
          { id: 1, title: '单选题', description: 0, index: 1 },
          { id: 2, title: '填空题', description: 0, index: 0 },
          { id: 3, title: '多选题', description: 0, index: 0 },
        ]
      } else if (this.student_questionnaire_classify_id === '3') {
        this.stepArr = [{ id: 1, title: '单选题', description: 0, index: 1 }]
      } else if (this.student_questionnaire_classify_id === '5') {
        this.stepArr = [{ id: 1, title: '单选题', description: 0, index: 1 }]
      }

      const ret = await getQuestionnaireList(this.student_questionnaire_classify_id)
      if (ret.status !== 200 || ret.data.code !== '1000') {
        this.$message.error(ret.data.msg)
        return
      }
      this.student_questionnaire_classify_list = ret.data.data

      this.getQuestionnaireIndex()
    }
  },
  watch: {
    '$route.query.student_questionnaire_classify_id' () {
      this.student_questionnaire_classify_id = this.$route.query.student_questionnaire_classify_id
      this.active = 1
      this.selectedChatIndex = 0
      this.selectedQuestionnaireClassifyIndex = 0
      this.init()
    }
  },
  created () {
    this.student_questionnaire_classify_id = this.$route.query.student_questionnaire_classify_id
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.questionnaire {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .questionnaire-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .questionnaire-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    .main-option {
      border-bottom: 1px solid #fff;
      height: 40px;
      margin-bottom: 20px;
      display: flex;
      .option-list {
        cursor: pointer;
        padding: 10px 30px;
        font-size: 14px;
      }
      .active {
        background-color: #fff;
      }
    }
    .main-head {
      margin-bottom: 40px;
    }
    .main-content {
      padding: 16px;
      box-sizing: border-box;
      background-color: #fff;
      height: 50%;
      display: flex;
      flex-direction: column;
      .question-block {
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 28px;
          margin-bottom: 20px;
        }
        .block-arr {
          display: flex;
          flex-direction: column;
          .option {
            margin-bottom: 12px;
          }
        }
      }
      .question-button {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .next-button {
          background-color: #409eff;
          color: #fff;
          border: none;
          transition: background-color 0.3s ease;
          &:hover {
            background-color: #66b1ff;
          }
        }
      }
    }
  }
}
</style>